import {
  MeetingJoinMethod,
  JoinMethodType,
  PhoneNumber,
  SortedPhoneNumbers
} from '../api/connection.types';
import { CONNECT_TO_MEETING_LABEL } from '../constants';

export class JoinMethod {
  private readonly props: MeetingJoinMethod;
  private readonly transformedNumbersSet: SortedPhoneNumbers | null;

  public static create(joinMethodDetails: MeetingJoinMethod | null): JoinMethod | null {
    if (!joinMethodDetails) return null;

    return new JoinMethod(joinMethodDetails);
  }

  private static get regularConnectionTypeList(): JoinMethodType[] {
    return ['ZOOM', 'WEBEX', 'TEAMS', 'BLUEJEANS', 'OTHER', 'UNIQUE_JOINING_LINK', 'VERACAST', 'AUDIO_BRIDGE', 'PANELIST_LINK'];
  }

  private constructor(props: MeetingJoinMethod) {
    this.props = props;
    this.transformedNumbersSet = this.transformAndSortNumbersSet(props.phoneNumbersSet);

  }

  public getPhoneNumbersByCountry(country: string): string[] {
    if (!this.transformedNumbersSet || !country) return [];
    const entries = this.transformedNumbersSet[country] || [];
    return entries.map((entry: { telephoneNumber: string }) => entry.telephoneNumber);
  }

  public getCountries(): string[] | null {
    if (this.transformedAndSortedNumbersSet) {
      return Object.keys(this.transformedAndSortedNumbersSet);
    }
    return null;
  }

  public isKnovioConnectionType(): boolean {
    return this.type === 'KNOVIO';
  }

  public isAudioBridgeType(): boolean {
    return this.type === 'AUDIO_BRIDGE';
  }

  public isUniqueDialInType(): boolean {
    return this.type === 'UNIQUE_DIAL_IN';
  }

  public isShowcaseConnectionType(): boolean {
    return this.type === 'SHOWCASE_PAGE_PATH';
  }

  public isWebinarConnectionType(): boolean {
    return this.type === 'WEBINAR';
  }

  public isInPersonConnectionType(): boolean {
    return this.type === 'IN_PERSON';
  }

  public isRegularConnectionType(): boolean {
    return JoinMethod.regularConnectionTypeList.includes(this.type);
  }

  public get type(): JoinMethodType {
    return this.props.type;
  }

  public get order(): number {
    return this.props.order;
  }

  public get transformedAndSortedNumbersSet(): SortedPhoneNumbers | null {
    return this.transformedNumbersSet;
  }

  public get participantTelephoneNumbers(): string {
    return this.props.participantTelephoneNumbers ?? '';
  }

  public get conferenceId(): string {
    return this.props.conferenceId ?? '';
  }

  public get participantId(): string {
    return this.props.participantId ?? '';
  }

  public get connectionPassword(): string {
    return this.props.connectionPassword ?? '';
  }

  public get participantOneTapDial(): string {
    return this.props.participantOneTapDial ?? '';
  }


  public get meetingLocation(): string {
    return this.props.meetingLocation ?? '';
  }

  public get embedCodeId(): string {
    return this.props.embedCodeId ?? '';
  }

  public get connectionLink(): string {
    return this.props.connectionLink ?? '';
  }

  public get meetingInvitation(): string {
    return this.props.meetingInvitation ?? '';
  }

  public get showcasePagePath(): string {
    return this.props.showcasePagePath ?? '';
  }

  public get autoSyncEnabled(): boolean {
    return !!this.props.autoSyncEnabled;
  }

  public get detailsPlaceholderText(): string {
    return this.props.detailsPlaceholderText ?? '';
  }

  public get isKnovioAvailable(): boolean {
    return this.isKnovioConnectionType() && !!this.props.publishEnabled;
  }

  public get uniqueDialInCode(): string {
    return this.props.uniqueDialInCode ?? '';
  }

  public get joinMethodId(): number | undefined {
    return this.props.joinMethodId;
  }

  public get isRegistrationNeeded(): boolean {
    return this.props.registrationNeeded;
  }

  public setUniqueDialInCode(code: string): void {
    this.props.uniqueDialInCode = code;
  }

  public get passportLabel(): string {
    return this.props?.displayConfiguration?.passportLabel ?? '';
  }

  public get passportDescription(): string {
    return this.props?.displayConfiguration?.passportDescription ?? '';
  }

  public get connectButtonLabel(): string {
    return this.props?.displayConfiguration?.connectButtonLabel ?? CONNECT_TO_MEETING_LABEL;
  }

  public get connectionDetailsEnabled(): boolean {
    const displayConfiguration = this.props?.displayConfiguration;
    return displayConfiguration === null ? true : !!displayConfiguration?.connectionDetailsEnabled;
  }

  public get showHideButtonEnabled(): boolean {
    const displayConfiguration = this.props?.displayConfiguration;
    return displayConfiguration === null ? true : !!displayConfiguration?.showHideButtonEnabled;
  }

  public get showDialInInfo(): string {
    return this.props?.displayConfiguration?.showDialInInfo ?? 'Show Dial-In Info';
  }

  public get hideDialInInfo(): string {
    return this.props?.displayConfiguration?.hideDialInInfo ?? 'Hide Dial-In Info';
  }

  private transformAndSortNumbersSet(phoneNumbersSet: PhoneNumber[] | null | undefined): SortedPhoneNumbers | null {
    if (phoneNumbersSet && phoneNumbersSet.length > 0) {
      const transformed = phoneNumbersSet.reduce<SortedPhoneNumbers>((result, item) => {
        const country = item.country;
        if (!result[country]) {
          result[country] = [];
        }
        result[country].push(item);
        return result;
      }, {});

      const sortedByKeys = Object.keys(transformed).sort((a, b) => {
        const priorityA = transformed[a][0].priority;
        const priorityB = transformed[b][0].priority;

        if (priorityA !== priorityB) {
          return priorityA - priorityB;
        } else {
          return a.localeCompare(b);
        }
      });

      const sortedObject: SortedPhoneNumbers = {};
      sortedByKeys.forEach(key => {
        sortedObject[key] = transformed[key].sort((a, b) => a.telephoneNumber.localeCompare(b.telephoneNumber));
      });

      return sortedObject;
    }
    return null;
  }
}
