import React, { useState } from 'react';
import DialInSelect from '../../../components/_base/DialInSelect';
import { getUnformattedPhoneNumber, parsePhoneNumbers } from '../../../lib/helpers/text-formatters';
import Icon from '../../../components/_base/Icon';
import { JoinMethod } from '../../../lib/join-method/join-method';

import './DialInInfo.scss';
import { ONE_TAP_FORMULA, STRING_WITH_HYPHEN_REGEX } from '../../../lib/constants';

export type DialInInfoProps = {
  joinMethod: JoinMethod;
}

const DialInInfo: React.FunctionComponent<DialInInfoProps> = (props: DialInInfoProps): JSX.Element => {
  const {
    joinMethod
  } = props;

  const getFilledOneTapFormula = (oneTapFormula: string, phoneNumber: string): string => {
    const unformattedPhoneNumber = getUnformattedPhoneNumber(phoneNumber);
    if (oneTapFormula) {
      return joinMethod.type === 'UNIQUE_DIAL_IN'
        ? fillOneTapFormulaForUniqueDialIn(oneTapFormula, unformattedPhoneNumber)
        : fillOneTapFormulaForAudioBridge(oneTapFormula, unformattedPhoneNumber);
    }
    
    return unformattedPhoneNumber;
  };

  const fillOneTapFormulaForAudioBridge = (oneTapFormula: string, phoneNumber: string) => {
    return oneTapFormula
      .replace(ONE_TAP_FORMULA.PHONE_NUMBER, phoneNumber)
      .replace(ONE_TAP_FORMULA.CONFERENCE_ID, joinMethod.conferenceId)
      .replace(ONE_TAP_FORMULA.PARTICIPANT_ID, joinMethod.participantId)
      .replace(ONE_TAP_FORMULA.PASSCODE, joinMethod.connectionPassword);
  };

  const fillOneTapFormulaForUniqueDialIn = (oneTapFormula: string, phoneNumber: string) => {
    return oneTapFormula
      .replace(ONE_TAP_FORMULA.PHONE_NUMBER, phoneNumber)
      .replace(ONE_TAP_FORMULA.CONFERENCE_ID, '')
      .replace(ONE_TAP_FORMULA.PARTICIPANT_ID, joinMethod.uniqueDialInCode)
      .replace(ONE_TAP_FORMULA.PASSCODE, '');
  };

  const getBaseOneTapFormula = () => {
    if (
      joinMethod.transformedAndSortedNumbersSet
      && joinMethod.transformedAndSortedNumbersSet[countryInitial]
      && joinMethod.transformedAndSortedNumbersSet[countryInitial][0]?.oneTapFormula
    ) {
      return joinMethod.transformedAndSortedNumbersSet[countryInitial][0].oneTapFormula;
    } else {
      return '';
    }
  };

  const countries = joinMethod.getCountries();
  const parsedPhoneNumbers = parsePhoneNumbers(joinMethod.participantTelephoneNumbers, joinMethod.participantOneTapDial);

  const countryInitial = countries ? countries[0] : '';
  const phoneNumbersInitial = joinMethod.transformedAndSortedNumbersSet
    ? joinMethod.getPhoneNumbersByCountry(countryInitial)
    : parsedPhoneNumbers;

  const oneTapFormulaBase = getBaseOneTapFormula();

  const oneTapFormulaInitial = `tel:${getFilledOneTapFormula(oneTapFormulaBase, phoneNumbersInitial[0])}`;

  const formattedDialInCode = joinMethod?.uniqueDialInCode.replace(STRING_WITH_HYPHEN_REGEX,'$1-');

  const [country, setCountry] = useState<string>(countryInitial);
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>(phoneNumbersInitial);
  const [phoneNumber, setPhoneNumber] = useState<string>(phoneNumbersInitial[0]);
  const [oneTapFormula, setOneTapFormula] = useState<string>(oneTapFormulaInitial);

  const handleCountryChange = (value: string): void => {
    const phoneNumbersByCounty = joinMethod.getPhoneNumbersByCountry(value) ?? [];
    setCountry(value);
    setPhoneNumbers(phoneNumbersByCounty);
    setPhoneNumber(phoneNumbersByCounty[0]);
    const filledOneTapFormula = getFilledOneTapFormula(oneTapFormulaBase, phoneNumbersByCounty[0]);
    setOneTapFormula(`tel:${filledOneTapFormula}`);
  };

  const selectTelephoneNumber = (value: string): void => {
    setPhoneNumber(value);
    const filledOneTapFormula = getFilledOneTapFormula(oneTapFormulaBase, value);
    setOneTapFormula(`tel:${filledOneTapFormula}`);
  };

  return (
    <div className='dial-in-info-wrapper'>
      {parsedPhoneNumbers &&
        <div className='dial-in-info__phone-number'>
          <span className='dial-in-info__phone-icon-wrapper'>
            <a
              className={`${!phoneNumbersInitial[0].length ? 'pointer-events' : ''} no-underline`}
              href={oneTapFormula}
              data-test-id='dial-in-link'
            >
              <Icon.PhoneIcon
                className='dial-in-info__phone-icon transform rotate-5'
                width='18px'
                height='18px'
              />
            </a>
          </span>
          <DialInSelect
            value={phoneNumber}
            onChange={selectTelephoneNumber}
            options={phoneNumbers}
          />
        </div>
      }
      {!joinMethod.uniqueDialInCode && (
        <>
          {joinMethod.conferenceId &&
            <div className='dial-in-info__field'>
              <label className='dial-in-info__label'>Conference ID</label>
              <span className='dial-in-info__value'>{joinMethod.conferenceId}</span>
            </div>
          }
          {joinMethod.participantId &&
            <div className='dial-in-info__field'>
              <label className='dial-in-info__label'>Participant ID</label>
              <span className='dial-in-info__value'>{joinMethod.participantId}</span>
            </div>
          }
          {joinMethod.connectionPassword &&
            <div className='dial-in-info__field'>
              <label className='dial-in-info__label'>Passcode</label>
              <span className='dial-in-info__value'>{joinMethod.connectionPassword}</span>
            </div>
          }
        </>
      )}
      {joinMethod.uniqueDialInCode &&
        <div className='dial-in-info__field'>
          <label className='dial-in-info__label'>Participant ID</label>
          <span className='dial-in-info__value'>{formattedDialInCode}</span>
        </div>
      }
      {joinMethod.transformedAndSortedNumbersSet && countries &&
        <div className='dial-in-info__field'>
          <label className='dial-in-info__label'>Country</label>
          <DialInSelect
            isCountryDropdown={true}
            value={country}
            onChange={handleCountryChange}
            options={countries}
          />
        </div>
      }
    </div>
  );
};

export default DialInInfo;
