import React, { useCallback, useMemo, useState } from 'react';
import Button from '../../../../components/button';
import CheckboxOption from '../../../../components/_base/CheckboxOption';
import Icon from '../../../../components/_base/Icon';
import { PublicSessionMeetingDetails } from '../../../../lib/api';
import { getFullDateInUsEuFormat, getTimeInLocalFormat } from '../../../../lib/helpers/dateHelper';
import { getGroupedMeetingsList } from '../../../../lib/helpers/meetingHelper';
import { DateFilter } from '../../SessionWrapper/DateFilter';
import './SessionChoices.scss';

interface IProps {
  data: PublicSessionMeetingDetails[];
  timeZone: string;
  onChange: (meetingId: number[]) => void;
  selectedMeetings: number[];
}

const SessionChoices = (props: IProps): JSX.Element => {
  const { data, timeZone, selectedMeetings, onChange } = props;
  const one = 1;

  const groupedMeetings = useMemo(() =>
    getGroupedMeetingsList<PublicSessionMeetingDetails>(data, false, timeZone),
  [data, timeZone]);

  const days: string[] = useMemo(() =>
    Object.keys(groupedMeetings),
  [groupedMeetings]);

  const [selectedDay, setSelectedDay] = useState<string>(days[0]);
  const currentIndex = days.indexOf(selectedDay);

  const isPrevButtonDisabled = currentIndex === 0;
  const isNextButtonDisabled = currentIndex + one === days.length;

  const dateFromSelectedDay = groupedMeetings[selectedDay][0].startDateTime;
  const formattedSelectedDay = getFullDateInUsEuFormat(dateFromSelectedDay, timeZone);

  const handleNextDayClick = () => {
    if (isNextButtonDisabled) return;
    setSelectedDay(days[currentIndex + one]);
  };

  const handlePrevDayClick = () => {
    if (isPrevButtonDisabled) return;
    setSelectedDay(days[currentIndex - one]);
  };

  const onSelectedDay = (day: string) => {
    setSelectedDay(day);
  };

  const handleSelectMeeting = (meetingId: number, isChecked: boolean) => {
    onChange(isChecked ? selectedMeetings.filter(el => el !== meetingId) : [...selectedMeetings, meetingId]);
  };

  const getSelectedCountByDay = useCallback((day: string) =>
    groupedMeetings[day].reduce((total, meeting) => {
      if (selectedMeetings.includes(meeting.meetingId)) {
        total += one;
      }

      return total;
    }, 0),
  [groupedMeetings, selectedMeetings]);

  const selectedCountPerDay = useMemo(() =>
    days.reduce((obj: { [day: string]: number }, day: string) => {
      obj[day] = getSelectedCountByDay(day);
      return obj;
    }, {}),
  [days, getSelectedCountByDay]);

  return (
    <div className='session-choices mt-3 word-break'>
      <div className='session-choices__content'>
        <label className='session-choices__label block font-bold text-sm'>Session Choices</label>
        <DateFilter
          dates={days}
          selectedCountPerDay={selectedCountPerDay}
          timeZone={timeZone}
          showAllDates={false}
          selectedDate={selectedDay}
          onSelectedDateChange={onSelectedDay}
          isTogglePastMeetingsBtnShown={false}
          withoutAllDays
        />
        <div className='session-choices__selected-day-label'>
          <h3 className='text-primary font-size-14px font-bold uppercase tracking-widest pt-3'>
            {formattedSelectedDay}
          </h3>
        </div>
        <div className='session-choices__meetings-list'>
          { groupedMeetings[selectedDay].map(meeting => {
            const { presenterOrganizations, startDateTime, endDateTime } = meeting;
            const orgs = presenterOrganizations || [];
            const formattedHostStartTime = getTimeInLocalFormat(startDateTime, timeZone);
            const formattedHostEndTime = getTimeInLocalFormat(endDateTime, timeZone);
            const isSelected = selectedMeetings.includes(meeting.meetingId);

            return (
              <div className='session-choices__meeting' key={meeting.meetingId}>
                <div className='session-choices__meeting_selection flex items-center'>
                  <CheckboxOption
                    onClick={() => handleSelectMeeting(meeting.meetingId, isSelected)}
                    isSelected={isSelected}
                  />
                  <div className='session-choices__meeting-time'>
                    <p className='lowercase tracking-widest'>
                      {`${formattedHostStartTime} - ${formattedHostEndTime}`}
                    </p>
                  </div>
                </div>
                <div className='session-choices__meeting-info'>
                  <h3 className='session-choices__meeting-topic pt-2'>
                    {meeting.topic}
                  </h3>
                  <div className='session-choices__meeting-presenters font-size-12px'>
                    {!!orgs.length && orgs.flatMap(o => o.attendees?.map((a) =>
                      <p className='py-2' key={a.attendeeId}>
                        <span className='font-semibold'>{`${a.firstName} ${a.lastName}`}</span>
                        {a.title && (a.firstName || a.lastName) ? ', ' : ''}{a.title}, {o.name}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className='session-choices__meetings-pagination'>
          <Button.Common
            disabled={isPrevButtonDisabled}
            name='Go to the previous day'
            className={'session-choices__day-button flex items-center rounded without-common-hover on-hover-shadow outline-auto-visible text-primary'}
            onClick={handlePrevDayClick}
            aria-label='Go to the previous day'
          >
            <Icon.RightArrow
              height='14px'
              width='14px'
              className='fill-primary transform rotate-180 mr-2'
              title='Previous day button'
              desc='Go to the previous day'
            />
            Previous Day
          </Button.Common>
          <div className='session-choices__footer flex'>
            <div className='session-choices__current-day'>
              {selectedDay}
            </div>
            <div className='session-choices__selected_count ml-1'>
              {`${selectedCountPerDay[selectedDay]} Selected`}
            </div>
          </div>
          <Button.Common
            disabled={isNextButtonDisabled}
            name='Go to the next day'
            className={'session-choices__day-button flex items-center rounded without-common-hover on-hover-shadow outline-auto-visible text-primary'}
            onClick={handleNextDayClick}
            aria-label='Go to the next day'
          >
            Next Day
            <Icon.RightArrow
              height='14px'
              width='14px'
              className='fill-primary transform ml-2'
              title='Next day button'
              desc='Go to the next day'
            />
          </Button.Common>
        </div>
      </div>
    </div>
  );
};

export default SessionChoices;
