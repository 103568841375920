import React from 'react';
import { useLocation } from 'react-router-dom';
import { BACKGROUND_IMG_URL, ERROR_MESSAGE } from '../../lib/constants';

export const ErrorPage: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const displayError = queryParams.get('displayError') || ERROR_MESSAGE;


  return (
    <div className="flex flex-col items-center justify-center h-64 mt-16">
      <div className="flex mt-40 text-red-500 font-medium">
        {displayError}
      </div>
      <img className="background-img" src={BACKGROUND_IMG_URL} alt='' />
    </div>
  );
};